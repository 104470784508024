<template>
    <div class="layout-dashboard">
        <div class="p-grid">
            <div class="p-col-12 p-pb-0">
                <div class="p-grid">
                    <div class="p-col">
                        <div class="card overview-box white">
                            <div class="overview-info p-col-12">
                                <h3 id="textcentre" v-if="userType === 'super_admin'">환영합니다, 슈퍼 관리자님</h3>
                                <h3 id="textcentre" v-else-if="userType === 'manager'">환영합니다 {{email}}.</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
export default {
    data() {
        return {
            errmsg: '',
            helptxt: true,
            resetemail: '',
            display: false,
            email_err: '',
            pass_err: '',
            email: localStorage.getItem('UserEmail'),
            password: '',
            userType: localStorage.getItem('userType'),
            error: {},
            errors: {},
        };
    },
    methods: {
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },

        goDashboard() {
            window.location = '/';
        },
    },
};
</script>

<style lang="scss" scoped>
.layout-dashboard {
    .orders {
        h4 {
            margin-bottom: 20px;
        }

        .p-button {
            margin-top: -20px;
        }

        .order-tabs {
            margin-bottom: 1rem;

            .order-tab {
                padding: 1rem 2rem 2rem 1rem;
                position: relative;
                transition: box-shadow 0.2s;

                &:hover {
                    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
                    cursor: pointer;
                }

                i {
                    font-size: 1rem;
                    vertical-align: middle;
                }

                .order-label {
                    margin-left: 0.25rem;
                    vertical-align: middle;
                }

                .stat-detail-icon {
                    position: absolute;
                    right: 1rem;
                    top: 2.25rem;
                    height: 1rem;
                    width: 1rem;
                }

                img {
                    position: absolute;
                    bottom: 0;
                    left: 5%;
                    width: 90%;
                }
            }
        }
    }

    .overview-chart {
        overflow: auto;
    }

    .dashbard-demo-dropdown {
        min-width: 8rem;
        margin-left: auto;
    }

    .product-badge {
        border-radius: 2px;
        padding: 0.25em 0.5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.3px;

        &.status-instock {
            background: #c8e6c9;
            color: #256029;
        }

        &.status-outofstock {
            background: #ffcdd2;
            color: #c63737;
        }

        &.status-lowstock {
            background: #feedaf;
            color: #8a5340;
        }
    }

    h3#textcentre {
        text-align: center;
        font-size: 25px;
    }
}
</style>
